import React, { Dispatch, SetStateAction } from "react"
import { PrimaryBtn } from "components/library"
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { Link } from "gatsby"
import { CONNECT_BASE_URL } from "variables"
import { MoleculesIllustration } from "components/library/illustrations/molecules-illustration";

type P = {
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

export const Hero = ({ setModalOpen }: P) => {
  const { t } = useTranslation()
  const { language } = useI18next();

  return (
    <section className="w-full flex flex-col gap-4 sm:gap-8 relative">
      <MoleculesIllustration className="absolute hidden sm:block -right-32 top-0 h-full w-auto z-[-1] transform rotate-180" />

      <span className="uppercase text-sm sm:text-lg lg:text-xl bg-gradient-to-r from-theme-orange to-theme-blue bg-clip-text text-transparent max-w-max">
        {t("common.words.data-management")}
      </span>

      <span className="text-[40px] sm:text-5xl md:text-6xl lg:text-[68px] leading-tight sm:leading-normal max-w-4xl">
        {t("connect-page.hero.heading")}
      </span>
      <p className="text-2xl">
        <Trans
          i18nKey="connect-page.hero.subheading"
          components={{
            bold: <span className="text-theme-orange" />,
          }}
        />
      </p>

      <div className="flex flex-col gap-4">
        <Link to={language === "ja" ? null : CONNECT_BASE_URL} target="_blank">
          <PrimaryBtn
            onClick={() => language === "ja" && setModalOpen(true)}
            className="md:text-lg w-full sm:max-w-max"
          >
            {t("common.cta.getStarted")}
          </PrimaryBtn>
        </Link>
      </div>
    </section>
  );
}
